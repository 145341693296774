import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.css'

class HomePageCarousel extends React.Component {

    getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    render() {
        const w = window.innerWidth;
        const images = [
            { src: w < 600 ? "assets/homepage-mobile-0.jpg" : "assets/homepage-0.jpg" },
            { src: w < 600 ? "assets/homepage-mobile-1.jpg" : "assets/homepage-1.jpg" },
            { src: w < 600 ? "assets/homepage-mobile-2.jpg" : "assets/homepage-2.jpg" },
            { src: w < 600 ? "assets/homepage-mobile-3.jpg" : "assets/homepage-3.jpg" },
            { src: w < 600 ? "assets/homepage-mobile-5.jpg" : "assets/homepage-5.jpg" },
            { src: w < 600 ? "assets/homepage-mobile-6.jpg" : "assets/homepage-6.jpg" },
        ];
        return (
            <Carousel
                autoPlay
                infiniteLoop
                swipeable={false}
                showIndicators={false}
                showThumbs={false}
                showArrows={false}
                stopOnHover={false}
                showStatus={false}
                interval={w < 600 ? 2000 : 5000}
                transitionTime={1000}
                animationHandler={'fade'}
            >
                {images.map((image, index) => (
                    <div key={index}>
                        <img className='homepage-carousel-img' src={image.src} />
                        <p className="homepage-name">
                            IVILINA AKULAVA
                            <div className="homepage-description-container">
                                <div className="homepage-description">
                                    <hr className='homepage-bar'></hr>
                                    photographe de mariage
                                </div>
                            </div>
                        </p>
                    </div>
                ))}
            </Carousel>
        )
    }
}

export default HomePageCarousel;
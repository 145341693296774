import React from 'react';

import './About.css'

class About extends React.Component {

    render() {
        return (
            <div className='about-background'>
                <div className='container about-container' id={'about'}>
                    <img className='about-title' src='./assets/about.svg'></img>
                    <h2>
                        Vous avez besoin d'une personne de confiance pour capturer un moment spécial ?
                    </h2>
                    <div className='about-content'>
                        <p>
                            Vous êtes au bon endroit ! J'ai découvert la photographie il y a 8 ans.
                            J'ai fait mes premiers pas dans le monde de la mode et des cosmétiques.

                            Au début, j'ai travaillé pour la presse, pour les marques et les couturiers connus. Au fur et à mesure, je me suis tournée vers des LoveStory. J’adore capturer des émotions, des gens pleins d'amour et des moments de vie remplis de bonheur.

                            Depuis je suis devenue une vraie passionnée des mariages. Le jour du mariage, chaque instant est intense. Je laisse mes mariés vivre à fond ce jour, sans retenue et mon approche devient clairement de "storytelling" pour mieux raconter leur histoire, tous les détails et avec mon style. Vous me verrez rarement immobile, car je suis toujours à la recherche de l'endroit parfait qui attirera l'attention. Donnez-moi un peu de lumière, un couple amoureux et vous allez me perdre pendant des heures.
                            <br></br>
                            <br></br>
                            Je vis et travaille actuellement à Annecy, mais je me déplace sans aucun problème partout en France et dans le monde, alors n'hésitez pas à me présenter votre "projet" de célébration… et ne soyez pas timide :)
                        </p>
                        <img className='about-signature' src='/assets/ivilina-signature.svg'></img>
                    </div>
                </div>
            </div>
        )
    }
}

export default About
import React from "react";
import Carousel from 'better-react-carousel';
import styled from "styled-components";

import './Wedding.css'

const StyledBtn = styled.div`
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 20px;
  color: #8E8E8E;
  opacity: 1;
  cursor: pointer;
  top: 50%;
  z-index: 10;
  transition: all 0.25s;
  transform: ${({ type }) =>
        `translateY(-50%) ${type === 'left' ? 'rotate(180deg)' : ''}`};
  left: ${({ type }) => (type === 'left' ? '20px' : 'initial')};
  right: ${({ type }) => (type === 'right' ? '20px' : 'initial')};
  &:hover {
    color: white;
    opacity: 1;
  }
`

class Wedding extends React.Component {
    render() {
        const LeftBtn = <StyledBtn type="left">{">"}</StyledBtn>
        const RightBtn = <StyledBtn type="right">{">"}</StyledBtn>
        return (
            <div className="weddings-container">
                <h2 className="weddings-title">MES DERNIERS MARIAGES</h2>
                <div className="wedding-cards-container">
                    <div className="wedding-card" id="wedding-card-1">
                        <div className="wedding-carousel">
                            <Carousel cols={1} rows={1} gap={10} loop arrowRight={RightBtn} arrowLeft={LeftBtn}>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-1/IVI_1.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-1/IVI_2.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-1/IVI_3.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-1/IVI_5.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-1/IVI_6.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-1/IVI_7.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-1/IVI_8.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-1/IVI_9.jpg" />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className="wedding-description">
                            <h2>VICTORIA & VASSILY</h2>
                            <p>12.07.2022</p>
                        </div>
                    </div>
                    <div className="wedding-card" id="wedding-card-2">
                        <div className="wedding-carousel">
                            <Carousel cols={1} rows={1} gap={10} loop arrowRight={RightBtn} arrowLeft={LeftBtn}>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-2/IVI_0.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-2/IVI_1.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-2/IVI_2.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-2/IVI_3.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-2/IVI_5.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-2/IVI_6.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-2/IVI_7.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-2/IVI_8.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-2/IVI_9.jpg" />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className="wedding-description">
                            <h2>AGATHA & ARNAUD</h2>
                            <p>07.01.2023</p>
                        </div>
                    </div>
                    <div className="wedding-card" id="wedding-card-3">
                        <div className="wedding-carousel">
                            <Carousel cols={1} rows={1} gap={10} loop arrowRight={RightBtn} arrowLeft={LeftBtn}>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-3/IVI_1.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-3/IVI_2.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-3/IVI_3.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-3/IVI_5.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-3/IVI_6.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-3/IVI_7.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-3/IVI_8.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-3/IVI_9.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-3/IVI_10.jpg" />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className="wedding-description">
                            <h2>AJII & DOMINIC</h2>
                            <p>27.08.2022</p>
                        </div>
                    </div>
                    <div className="wedding-card" id="wedding-card-4">
                        <div className="wedding-carousel">
                            <Carousel cols={1} rows={1} gap={10} loop arrowRight={RightBtn} arrowLeft={LeftBtn}>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-4/IVI_1.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-4/IVI_2.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-4/IVI_3.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-4/IVI_5.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-4/IVI_6.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-4/IVI_7.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-4/IVI_8.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-4/IVI_9.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-4/IVI_10.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-4/IVI_11.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-4/IVI_12.jpg" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="/assets/wedding-4/IVI_13.jpg" />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className="wedding-description">
                            <h2>DARIA & CLEMENT</h2>
                            <p>15.10.2022</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Wedding;
import React from "react"

import './BannerVideo.css';

class BannerVideo extends React.Component {

    render() {
        return (
            <div className="banner-container-parallax">
                <video className='video-background' autoPlay loop muted>
                    <source src={"./assets/wedding_footage.mp4"} type='video/mp4' />
                </video>
            </div>
        )
    }
}

export default BannerVideo;
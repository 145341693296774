import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import HomePage from './components/HomePage';
import Gallery from './components/Gallery';
import Navbar from './components/Navbar';

function App() {
  return (
    <Router>
      <Navbar></Navbar>
      <Routes>
        <Route path='/' element={<HomePage></HomePage>}>
        </Route>
        <Route path='/gallery' element={<Gallery></Gallery>}>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

import React from "react";
import { PhotoAlbum } from "react-photo-album";
import { photos } from "./photo";

class Gallery extends React.Component {


    getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    render() {
        const w = window.innerWidth;
        return (
            <PhotoAlbum
                layout="masonry"
                photos={photos}
                padding={0}
                spacing={w < 600 ? 10 : 12}
                columns={w < 600 ? 2 : 3}
            ></PhotoAlbum>
        )
    }
}

export default Gallery
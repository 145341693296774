import React from 'react';
import HomePageCarousel from './Carousel';
import About from './About';
import BannerVideo from './BannerVideo';
import Description from './Description';
import Stats from './Stats';

import './HomePage.css'
import Sample from './Sample';
import Wedding from './Weddings';
import Contact from './Contact';

class HomePage extends React.Component {
    render() {
        return (
            <div className='homepage'>
                <HomePageCarousel></HomePageCarousel>
                <About></About>
                <Sample></Sample>
                <BannerVideo></BannerVideo>
                <Stats></Stats>
                <Wedding></Wedding>
                <Description></Description>
                {/* <Contact></Contact> */}
            </div>
        )
    }
}

export default HomePage
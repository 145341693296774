import React from "react";
import Fade from "react-reveal/Fade";


import "./Description.css"

class Description extends React.Component {

    render() {
        return (
            <div>
                <div className="description-background">
                    <div className="container pack-description-container" id="description">
                        <h2>MES TARIFS</h2>
                        <Fade delay={0}>
                            <p className="description-li description-p">
                                Je vous offre différents packs pour la couverture de votre <b>mariage en France et en Suisse</b>, qui comprennent tous un ensemble de services pour vous fournir des images uniques et inoubliables.
                            </p>
                        </Fade>
                        <br></br>
                        <Fade delay={500}>
                            <p className="description-li description-p">
                                Lors de <b>notre première rencontre</b>, que ce soit par téléphone, Zoom ou en personne, nous discutons de vos attentes, vos souhaits et vos préférences pour le <b>reportage photographique de votre mariage</b>. Je prends le temps de comprendre ce que vous attendez de photographe de mariage, afin de m’assurer que je suis en mesure de fournir <b>un service personnalisé</b> et adapté à vos besoins.
                            </p>
                        </Fade>
                        <br></br>
                        <Fade delay={1000}>
                            <p className="description-li description-p">
                                Je m'occupe ensuite de la <b>post-production</b> de toutes les images que j’ai capturé lors de votre mariage, en utilisant des techniques de retouche pour les sublimer et les rendre uniques. Je travaille avec soin pour que chaque image soit un véritable reflet de votre <b>journée de mariage</b>, en utilisant mon savoir-faire et ma créativité pour produire des <b>photos uniques et mémorables</b>.
                            </p>
                        </Fade>
                        <br></br>
                        <Fade delay={1500}>
                            <p className="description-li description-p">
                                Je vous fournis ensuite <b>toutes vos photos en haute définition</b>, sans logo et sans restriction d'utilisation, afin que vous puissiez les partager librement avec votre famille et vos amis. Je vous offre également un <b>packaging élégant</b> et une clé USB pour stocker toutes vos images, ainsi qu'une livraison rapide et sécurisée de votre reportage photographique.
                            </p>
                        </Fade>
                        <br></br>
                        <Fade delay={2000}>
                            <p className="description-li description-p">
                                Pour rendre le processus encore plus pratique pour vous, <b>j’offre les frais de transport pour les mariages à Annecy</b>. Pour les mariages en dehors de cette zone, je facture un supplément en fonction de la distance parcourue, au tarif de 0,6 € / km, au-delà de 50 km d’Annecy.
                            </p>
                        </Fade >
                    </div>
                </div>
                <div className="container pack-container">
                    <div>
                        <img className="pack" src="./assets/pack-01.svg"></img>
                    </div>
                    <div>
                        <img className="pack" src="./assets/pack-02.svg"></img>
                    </div>
                    <div>
                        <img className="pack" src="./assets/pack-03.svg"></img>
                    </div>
                </div>
            </div >

        )
    }
}

export default Description
import React from "react";
import { SocialIcon } from 'react-social-icons';

import "./Contact.css"

class Contact extends React.Component {
    render() {
        return (
            <div className="contact-container" id="contact">
                <h2>CONTACTEZ-MOI</h2>
                <div className="contact-social-container">
                    <div className="social-button">
                        <SocialIcon url="https://www.instagram.com/photographer_ivi_mariage/" bgColor="#8E8E8E"></SocialIcon>
                    </div>
                    <div className="social-button">
                        <SocialIcon media="email" url="mailto:ivilina.akulava.pro@gmail.com" bgColor="#8E8E8E"></SocialIcon>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;
import React from 'react';

import './Sample.css'

class Sample extends React.Component {

    render() {
        return (
            <div className='sample-background'>
                <h2>UN RENDU NATUREL ET LUMINEUX</h2>

                <div className="sample-row">
                    <div className="sample-column">
                        <img src="/assets/sample-1.jpg" width={"100%"} />
                    </div>
                    <div className="sample-column">
                        <img src="/assets/sample-2.jpg" width={"100%"} />
                    </div>
                    <div className="sample-column">
                        <img src="/assets/sample-3.jpg" width={"100%"} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Sample
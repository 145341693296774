import React from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import './Stats.css'

class Stats extends React.Component {

    render() {
        return (
            <div className="stats-container">
                <div className='stats-img-container'>
                    <img className='stats-img' src="/assets/stats.svg" />
                </div>
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={true}
                    className=""
                    containerClass="stats-container"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass="stats-carousel-image"
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 3,
                            partialVisibilityGutter: 40
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    <img src='/assets/stats/1.jpg' className='img-stats-card' />
                    <img src='/assets/stats/2.jpg' className='img-stats-card' />
                    <img src='/assets/stats/3.jpg' className='img-stats-card' />
                    <img src='/assets/stats/4.jpg' className='img-stats-card' />
                    <img src='/assets/stats/5.jpg' className='img-stats-card' />
                    <img src='/assets/stats/6.jpg' className='img-stats-card' />
                    <img src='/assets/stats/7.jpg' className='img-stats-card' />
                    <img src='/assets/stats/8.jpg' className='img-stats-card' />
                    <img src='/assets/stats/9.jpg' className='img-stats-card' />
                    <img src='/assets/stats/10.jpg' className='img-stats-card' />
                    <img src='/assets/stats/11.jpg' className='img-stats-card' />
                    <img src='/assets/stats/12.jpg' className='img-stats-card' />
                    <img src='/assets/stats/13.jpg' className='img-stats-card' />
                    <img src='/assets/stats/14.jpg' className='img-stats-card' />
                    <img src='/assets/stats/15.jpg' className='img-stats-card' />
                    <img src='/assets/stats/16.jpg' className='img-stats-card' />
                    <img src='/assets/stats/17.jpg' className='img-stats-card' />
                    <img src='/assets/stats/18.jpg' className='img-stats-card' />
                    <img src='/assets/stats/19.jpg' className='img-stats-card' />
                    <img src='/assets/stats/20.jpg' className='img-stats-card' />
                    <img src='/assets/stats/21.jpg' className='img-stats-card' />
                    <img src='/assets/stats/22.jpg' className='img-stats-card' />
                    <img src='/assets/stats/23.jpg' className='img-stats-card' />
                    <img src='/assets/stats/24.jpg' className='img-stats-card' />
                    <img src='/assets/stats/25.jpg' className='img-stats-card' />
                    <img src='/assets/stats/26.jpg' className='img-stats-card' />
                    <img src='/assets/stats/27.jpg' className='img-stats-card' />
                    <img src='/assets/stats/28.jpg' className='img-stats-card' />
                    <img src='/assets/stats/29.jpg' className='img-stats-card' />
                    <img src='/assets/stats/30.jpg' className='img-stats-card' />
                </Carousel>
            </div>
        )
    }
}

export default Stats
import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

import './Navbar.css'

class Navbar extends React.Component {

    render() {
        return (
            <header className="navbar-container">
                <div className="navbar">
                    <li className="tab tab-0">
                        <Link to="/gallery">PORTFOLIO</Link>
                    </li>
                    <li className="tab tab-1">
                        <Link to="/#about">A PROPOS</Link>
                    </li>
                    <li className="tab tab-logo">
                        <Link to="/">
                            <img className="logo" src="assets/ivilina-akulava-logo.svg"></img>
                        </Link>
                    </li>
                    <li className="tab tab-2">
                        <Link to="/#description">TARIFS</Link>
                    </li>
                    <li className="tab tab-3">
                        <a href="mailto: ivilina.akulava.pro@gmail.com">CONTACT</a>
                    </li>
                </div>
            </header>
        )
    }
}

export default Navbar